<template>
  <!-- eslint-disable -->
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.84 22.621"><path d="M7.655 12.778a2.219 2.219 0 110-4.437.584.584 0 010 1.167 1.052 1.052 0 101.052 1.052.584.584 0 011.167 0 2.221 2.221 0 01-2.219 2.218zM12.809 12.778a2.219 2.219 0 010-4.437.584.584 0 010 1.167 1.052 1.052 0 101.052 1.052.584.584 0 011.167 0 2.221 2.221 0 01-2.219 2.218zM18.186 12.778a2.219 2.219 0 110-4.437.584.584 0 010 1.167 1.052 1.052 0 101.052 1.052.583.583 0 111.166 0 2.221 2.221 0 01-2.218 2.218z"/><g><path d="M2.197 6.679a.586.586 0 01-.073.285v.017l-.019.02a8.205 8.205 0 00-.543 1.301.6.6 0 01-1.186-.136.627.627 0 01.016-.136.1.1 0 01.008-.027l.005-.016.008-.027a8.942 8.942 0 01.64-1.536l.005-.011a.6.6 0 011.139.263z"/></g><g><path d="M25.772 21.744a.6.6 0 01-.531.877.573.573 0 01-.181-.029l-6.922-2.2a15.216 15.216 0 01-5.218.9C5.796 21.292 0 16.517 0 10.645a.6.6 0 111.2 0v.04c.025 5.188 5.282 9.409 11.72 9.409a13.976 13.976 0 005.071-.93.584.584 0 01.217-.041.606.606 0 01.407.16l5.415 1.725-2.014-3.836a.6.6 0 01.108-.686 9.151 9.151 0 001.811-2.6 7.694 7.694 0 00.292-.741l.024-.068c.055-.17.1-.337.144-.5.048-.185.089-.369.122-.549a7.836 7.836 0 00.123-1.383 8.115 8.115 0 00-1.783-5 9.516 9.516 0 00-.343-.417 10.155 10.155 0 00-1.023-1.016l-.037-.033a11.638 11.638 0 00-2.335-1.545c-.153-.078-.306-.153-.457-.22s-.309-.138-.457-.2a12.97 12.97 0 00-1.62-.543 14.21 14.21 0 00-3.666-.474 13.992 13.992 0 00-5.722 1.208 12.105 12.105 0 00-1.046.532 10.868 10.868 0 00-2.779 2.226l-.016.018a.6.6 0 01-1.049-.4.59.59 0 01.153-.4l.021-.023A11.983 11.983 0 015.14 2.15c.152-.094.305-.186.458-.271.17-.1.336-.185.492-.264A15 15 0 0111.382.079c.427-.042.864-.067 1.3-.073h.236a15.408 15.408 0 013.962.512 14.332 14.332 0 011.657.549 11.9 11.9 0 01.914.403 13.492 13.492 0 012.017 1.207 12.081 12.081 0 01.96.775l.035.032a9.659 9.659 0 013.375 7.168 8.731 8.731 0 01-.1 1.329 9.342 9.342 0 01-.209.987 8.728 8.728 0 01-.3.918 10.082 10.082 0 01-1.946 3.128z"/></g></svg>
</template>

<script>
  export default {
    name : 'MeepIconHomepageChat'
  };
</script>
