<template>
  <!-- eslint-disable -->
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.694 37.165"><defs/><g id="notification_mobile_svg__Groupe_152" transform="translate(-8302.228 -2793.185)"><g id="notification_mobile_svg__Groupe_150" transform="translate(8302.428 2793.385)"><path id="notification_mobile_svg__Tracé_343" d="M8318.529 2830.149h-11.174a4.934 4.934 0 01-4.928-4.928v-26.908a4.934 4.934 0 014.928-4.929h11.174a4.934 4.934 0 014.928 4.929v26.908a4.934 4.934 0 01-4.928 4.928zm-11.174-35.836a4 4 0 00-4 4v26.908a4 4 0 004 4h11.174a4 4 0 004-4v-26.908a4 4 0 00-4-4z" transform="translate(-8302.428 -2793.385)"/><path id="notification_mobile_svg__Rectangle_278" d="M0 0h20.1v.928H0z" transform="translate(.464 5.021)"/><path id="notification_mobile_svg__Rectangle_279" d="M0 0h20.1v.928H0z" transform="translate(.464 30.815)"/></g><g id="notification_mobile_svg__Groupe_151" transform="translate(8327.414 2802.226)"><path id="notification_mobile_svg__Tracé_344" d="M8437.5 2876.009a.465.465 0 01-.328-.793 5.739 5.739 0 000-8.1.463.463 0 01.654-.656 6.663 6.663 0 010 9.413.461.461 0 01-.326.136z" transform="translate(-8437.034 -2861.625)"/><path id="notification_mobile_svg__Tracé_345" d="M8451.638 2860.093a.464.464 0 01-.328-.792 12.386 12.386 0 000-17.5.464.464 0 01.656-.656 13.314 13.314 0 010 18.811.46.46 0 01-.328.137z" transform="translate(-8448.549 -2841.01)"/></g></g></svg>
</template>

<script>
  export default {
    name : 'MeepIconNotificationMobile'
  };
</script>
