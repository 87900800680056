<template>
  <section class="setting communicate" :class="settingCardClass">
    <Banner>
      <div class="banner-content">
        <MeepIconChat class="banner-content__icon banner-content__icon-chat" />
        <span class="banner-content__text">{{ $t("menu.chat") }}</span>
      </div>
    </Banner>
    <div class="setting-cards page-layout">
      <SettingCard
        :title="$t('communicate.chat')"
        :content="$t('communicate.chat-content')"
        link="/dashboard/communicate/chat/"
      >
        <MeepIconHomepageChat class="app-icon" />
      </SettingCard>
      <SettingCard
        v-if="!isClient && !isEmployee"
        :title="$t('communicate.news')"
        :content="$t('communicate.news-content')"
        link="/dashboard/communicate/organization-news/"
      >
        <MeepIconOrganizationNew class="app-icon" />
      </SettingCard>
      <SettingCard
        v-if="isAdmin"
        :title="$t('communicate.notification')"
        :content="$t('communicate.notification-content')"
        link="/dashboard/communicate/pushs"
      >
        <MeepIconNotificationMobile class="app-icon" />
      </SettingCard>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import Banner from "@/components/Banner";
import SettingCard from "@/components/SettingCard";
import MeepIconChat from "@/components/icons/MeepIconChat.vue";
import MeepIconHomepageChat from "@/components/icons/MeepIconHomepageChat.vue";
import MeepIconNotificationMobile from "@/components/icons/MeepIconNotificationMobile.vue";
import MeepIconOrganizationNew from "@/components/icons/MeepIconOrganizationNew.vue";

export default {
  name: "Setting",
  components: {
    Banner,
    SettingCard,
    MeepIconChat,
    MeepIconHomepageChat,
    MeepIconNotificationMobile,
    MeepIconOrganizationNew,
  },
  props: [],
  data() {
    return {
      newCompanies: [],
    };
  },
  computed: {
    ...mapGetters(["isGC", "isLCM", "isEmployee", "isClient", "isAdmin"]),
    settingCardClass() {
      return { "setting-card-gc": this.isGC, "setting-card-lcm": this.isLCM };
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/_variable.scss";

.setting {
  &-cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 34px;
  }
  &-card-text {
    padding: 0 5px 35px 5px;
  }
  &-card-title {
    font-size: 36px;
  }
}
.setting-card-gc {
  .setting-card-title {
    font-size: 35px;
  }
}

.setting-card-lcm {
  .setting-card-title {
    font-size: toRem(36);
  }
}

.communicate {
  .setting-card-text {
    width: 100%;
    letter-spacing: normal;
  }
}
</style>
